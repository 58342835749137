@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    background-image: url('../images/bg.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
}

